import { GET_DEVICE_INFO } from "../Constants/Constants";

/**
 *
 * @param {string} partNum the part number of the VeloCloud device
 */
export async function getProductDetails(partNum) {
  const request = GET_DEVICE_INFO + partNum;
  console.log(request);

  var response = await fetch(request, { method: "GET" }).catch((err) => {
    console.error(err);
  });

  var body = await response.json();

  // console.log(body.device);

  return body.device;
}
