import React, { PureComponent } from "react";

import { getProductDetails } from "../../Utilities/Utilities";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
// import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CartCard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { deviceInfo: null, quantity: this.props.cartItem.quantity };
    this.generatePaymentString = this.generatePaymentString.bind(this);
    this.generatePaymentLengthString = this.generatePaymentLengthString.bind(
      this
    );
    this.generateTotalPayment = this.generateTotalPayment.bind(this);
  }

  async componentDidMount() {
    var deviceInfo = await getProductDetails(this.props.cartItem.partNum);
    console.log(deviceInfo);
    this.setState({ deviceInfo: deviceInfo });
  }

  async componentDidUpdate(prevProps, prevState) {
    // console.log(this.props);
    // console.log(prevProps);
    if (this.props !== prevProps) {
      // if the current props are different from the last props
      var deviceInfo = await getProductDetails(this.props.cartItem.partNum);

      this.setState({ deviceInfo: deviceInfo });
    }
    if (this.state !== prevState) {
      // if the state is updated, we will sync the update with the global state
      this.props.updateItemInCart(
        this.props.cartItem.partNum,
        this.state.quantity
      );
    }
  }

  generatePaymentString() {
    if (this.state.deviceInfo.payment.type === "annual") {
      return "/year";
    }
    if (this.state.deviceInfo.payment.type === "prepaid") {
      return "";
    }
    if (this.state.deviceInfo.payment.type === "monthly") {
      return "/month";
    }
  }

  generatePaymentLengthString() {
    if (this.state.deviceInfo.payment.type === "prepaid") {
      return "One-Time Charge";
    } else {
      return this.state.deviceInfo.payment.length + " Month Term";
    }
  }

  generateTotalPayment() {
    if (this.state.deviceInfo.payment.type === "prepaid") {
      return this.state.deviceInfo.price;
    } else if (this.state.deviceInfo.payment.type === "annual") {
      return (
        (this.state.deviceInfo.price * this.state.deviceInfo.payment.length) /
        12
      );
    } else {
      return this.state.deviceInfo.price * this.state.deviceInfo.payment.length;
    }
  }

  componentWillUnmount() {
    /** we store the quantity locally until the component unmounts. at that point, the state is synced with the global state */
    this.props.updateItemInCart(
      this.props.cartItem.partNum,
      this.state.quantity
    );
  }

  render() {
    var formatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: "USD",
    });
    // console.log(this.state.deviceInfo);
    if (this.state.deviceInfo === null) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        <Card>
          <Card.Body>
            <Card.Title>
              <Row>
                <Col>
                  <b>
                    {this.state.deviceInfo.HWDevice
                      ? this.state.deviceInfo.HWDevice.name
                      : this.state.deviceInfo.product}
                  </b>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <Button
                    variant="outline-danger"
                    onClick={() => {
                      this.props.removeItemFromCart(
                        this.state.deviceInfo.partNum
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={["fas", "times-circle"]} />
                  </Button>
                </Col>
                {/* {this.state.deviceInfo.product} */}
              </Row>
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {this.state.deviceInfo.HWDevice
                ? this.state.deviceInfo.product
                : this.state.deviceInfo.partNum}
            </Card.Subtitle>
            <hr />
            <Card.Text>{this.state.deviceInfo.comments}</Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Title>
              {/* <InputGroup>
                <InputGroup.Prepend>
                  <Button variant="outline-secondary">-</Button>
                </InputGroup.Prepend>
                <FormControl type="number" value={this.state.quantity} />
                <InputGroup.Append>
                  <Button variant="outline-secondary">+</Button>
                </InputGroup.Append>
              </InputGroup> */}
              <Row>
                <Col>
                  <Form>
                    <Form.Row className="align-items-center">
                      <Col xs="auto">
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => {
                            this.setState({
                              quantity: this.state.quantity - 1,
                            });
                          }}
                        >
                          -
                        </Button>
                      </Col>
                      <Col xs="4">
                        <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                          Quantity
                        </Form.Label>
                        <FormControl
                          type="number"
                          value={Number(this.state.quantity)}
                          onChange={(e) => {
                            this.setState({
                              quantity: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col xs="auto">
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => {
                            this.setState({
                              quantity: Number(this.state.quantity) + 1,
                            });
                          }}
                        >
                          +
                        </Button>
                      </Col>
                    </Form.Row>
                  </Form>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <i>{this.generatePaymentLengthString()}</i>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <b>
                    {formatter.format(
                      this.state.deviceInfo.price * this.state.quantity
                    )}
                    {this.generatePaymentString()}
                  </b>
                </Col>
                <Col style={{ textAlign: "right" }} size="auto">
                  <b>
                    {formatter.format(
                      this.generateTotalPayment() * this.state.quantity
                    )}
                  </b>
                </Col>
              </Row>
            </Card.Title>
          </Card.Footer>
        </Card>
        <br />
      </div>
    );
  }
}

export default CartCard;
