import React, { PureComponent } from "react";

import HWCard from "../HWCard/HWCard";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
// import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";

import HWCardClasses from "../HWCard/HWCard.module.css";

import { GET_SEARCH } from "../../Constants/Constants";

const loadingHTML = [
  <div key={"loadingHTML"} style={{ margin: "0 auto" }}>
    <Spinner animation="grow" variant="primary" />
  </div>,
];

const MAX_SEARCH_NUM = 250;

class HWResults extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { results: loadingHTML, numResults: 0 };

    this.generateHTML = this.generateHTML.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      // this.setState({ results: loadingHTML, numResults: 0 });
      const request =
        GET_SEARCH +
        this.props.paymentType +
        "/" +
        this.props.paymentLength +
        "/" +
        this.props.serviceLevel +
        "/" +
        this.props.deviceType +
        "/" +
        this.props.bandwidth;

      console.log(request);

      var response = await fetch(request, { method: "GET" }).catch((err) => {
        console.error(err);
      });

      var body = await response.json();

      var html = this.generateHTML(body);

      this.setState({ results: html, numResults: body.length });
    }
  }

  async componentDidMount() {
    // this.setState({ results: loadingHTML });
    const request =
      GET_SEARCH +
      this.props.paymentType +
      "/" +
      this.props.paymentLength +
      "/" +
      this.props.serviceLevel +
      "/" +
      this.props.deviceType +
      "/" +
      this.props.bandwidth;

    console.log(request);

    var response = await fetch(request, { method: "GET" }).catch((err) => {
      console.error(err);
    });

    var body = await response.json();

    var html = this.generateHTML(body);

    this.setState({ results: html, numResults: body.length });
    // const request = GET_ALL_PART_NUM;
    // console.log(request);
    // var response = await fetch(request, { method: "GET" }).catch((err) => {
    //   console.error(err);
    // });
    // var body = await response.json();
    // var html = this.generateHTML(body);
    // console.log(html);
    // this.setState({ results: html });
  }

  generateHTML(partNums) {
    var output = [];
    var searchBound = partNums.length;
    if (partNums.length > MAX_SEARCH_NUM) {
      searchBound = MAX_SEARCH_NUM;
    }
    // if (this.props.bandwidth !== "na") {
    //   output.push(
    //     <Col key={"BandwidthBetaWarning"}>
    //       <Card className={HWCardClasses.HWCard} bg="warning">
    //         <Card.Body>
    //           <Card.Title>Bandwidth Search is a Beta Feature</Card.Title>
    //           <hr />
    //           <Card.Text>
    //             You may notice that not all devices are listed when using this
    //             feature.
    //             <br />
    //             The following VeloCloud devices are not currently supported with
    //             Bandwidth Search and will not appear in search results:
    //             <ui>
    //               <li>
    //                 <b>Edge 680</b>
    //               </li>
    //               <li>
    //                 <b>Edge 640</b>
    //               </li>
    //               <li>
    //                 <b>Edge 620</b>
    //               </li>
    //             </ui>
    //           </Card.Text>
    //         </Card.Body>
    //       </Card>
    //     </Col>
    //   );
    // }
    for (var i = 0; i < searchBound; i++) {
      output.push(
        <Col key={partNums[i]}>
          <HWCard
            partNum={partNums[i]}
            quantity={this.props.quantity}
            addItemToCart={this.props.addItemToCart}
            removeItemFromCart={this.props.removeItemFromCart}
            cart={this.props.cart}
          />
        </Col>
      );
    }
    if (output.length === 0) {
      output.push(
        <Col>
          <Card bg="danger" className={HWCardClasses.HWCard} text="white">
            <Card.Body>
              <Card.Title>No Results Found</Card.Title>
              <hr />
              <Card.Text>
                <p>Try changing your search parameters</p>
                <p>
                  If you believe this is a mistake, please consider filing a bug
                  report with{" "}
                  <a
                    href="mailto:hbromund@linclogix.com"
                    style={{ color: "white", textDecoration: "underline" }}
                  >
                    Hugh Bromund
                  </a>
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      );
    }

    return output;
  }

  render() {
    return (
      <div>
        <Badge variant="secondary" hidden={this.state.results.length - 1 === 0}>
          {this.state.numResults} Devices Found
        </Badge>
        &nbsp;
        <Badge variant="primary" hidden={this.state.results.length - 1 === 0}>
          {this.state.results.length} Shown
        </Badge>
        <Row xs={1} sm={1} md={2} lg={3} xl={3}>
          {this.state.results}
        </Row>
      </div>
    );
  }
}

export default HWResults;
