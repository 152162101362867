import React, { PureComponent } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-sliding-pane/dist/react-sliding-pane.css";
import SlidingPane from "react-sliding-pane";

import { CartItem } from "../../Utilities/CartItem";

import Cart from "../Cart/Cart";
import HWResults from "../HWResults/HWResults";

import classes from "./HWSearch.module.css";

const PURCHASE = "purchase";
const RENT = "rent";
const NO_PREFERENCE_PAYMENT = "na";

const PAYMENT_PREPAID = "prepaid";
const PAYMENT_ANNUAL = "annual";
const PAYMENT_MONTHLY = "monthly";
const DEFAULT_PAYMENT_KEY = 0;
const TYPE_HARDWARE = "HW";
const TYPE_SOFTWARE = "SW";
const PAYMENT_LENGTH_MAP = new Map([
  ["na", "na"],
  [0, 1],
  [1, 3],
  [2, 12],
  [3, 24],
  [4, 36],
  [5, 48],
  [6, 60],
]);

const BANDWIDTH_MAP = new Map([
  [0, "na"],
  [1, 10],
  [2, 30],
  [3, 50],
  [4, 100],
  [5, 200],
  [6, 350],
  [7, 500],
  [8, 750],
  [9, 1000],
  [10, 2000],
  [11, 5000],
  [12, 10000],
]);

class HWSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showWelcome: true,
      payment: PURCHASE,
      paymentType: PAYMENT_PREPAID,
      paymentLength: DEFAULT_PAYMENT_KEY,
      serviceLevel: "na",
      replacementTerm: "na",
      quantity: 1,
      bandwidth: 0,
      deviceType: "na",
      showCart: false,
      cart: [],
    };

    this.addItemToCart = this.addItemToCart.bind(this);
    this.removeItemFromCart = this.removeItemFromCart.bind(this);
    this.updateItemInCart = this.updateItemInCart.bind(this);
  }
  /**
   *
   * @param {string} partNum the part number of the VeloCloud device
   * @param {number} quantity the number of devices to be added
   * @param {number} price the cost of the device
   */
  addItemToCart(partNum, quantity = 1, price) {
    for (var i = 0; i < this.state.cart.length; i++) {
      if (this.state.cart[i].partNum === partNum) {
        console.log(partNum + " is already in the Cart. Not adding");
        return;
      }
    }
    console.log("Adding " + partNum + " to Cart");
    // tempCart.push(new CartItem(partNum, quantity));
    this.props.successNotify(partNum);
    // this.setState({ cart: tempCart });
    this.setState((prevState) => ({
      cart: [...prevState.cart, new CartItem(partNum, quantity, price)],
    }));
  }

  removeItemFromCart(partNum) {
    this.props.errorNotify(partNum);
    this.setState((prevState) => ({
      cart: prevState.cart.filter(function (cartItem) {
        return partNum !== cartItem.partNum;
      }),
    }));
  }

  updateItemInCart(partNum, quantity = 0) {
    var i = 0;
    for (i = 0; i < this.state.cart.length; i++) {
      if (this.state.cart[i].partNum === partNum) {
        break;
      }
    }
    // console.log(i);
    var tempCart = [...this.state.cart];
    var cartItem = tempCart[i];
    if (cartItem !== undefined) {
      cartItem.quantity = Number(quantity);
      tempCart[i] = cartItem;
      this.setState((prevState) => ({
        cart: [...tempCart],
      }));
    }
  }

  render() {
    // console.log(this.state);
    return (
      <div className={classes.container}>
        <Row className={classes.row}>
          <Col sm={4} className={classes.searchCol}>
            <Container fluid>
              <h1>
                <b>Search</b>
              </h1>
              <hr />
              <Alert
                hidden={!this.state.showWelcome}
                style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" }}
                variant="primary"
                dismissible
                onClose={() => {
                  this.setState({ showWelcome: false });
                }}
              >
                <Alert.Heading>Welcome to Harbor!</Alert.Heading>
                <p>
                  Please choose the configurations you would like for your
                  VeloCloud Order.
                </p>
              </Alert>
              <Form.Group>
                <Form.Label className={classes.formLabel}>
                  Device Type
                </Form.Label>
                <ToggleButtonGroup
                  name="hwswoptions"
                  style={{ minWidth: "100%", flexWrap: "wrap" }}
                  defaultValue={this.state.deviceType}
                  onChange={(e) => {
                    if (e !== TYPE_HARDWARE) {
                      this.setState({ deviceType: e, bandwidth: 0 });
                    } else {
                      this.setState({ deviceType: e });
                    }
                  }}
                >
                  <ToggleButton value={TYPE_HARDWARE}>Hardware</ToggleButton>
                  <ToggleButton value={TYPE_SOFTWARE}>Software</ToggleButton>
                  <ToggleButton value={"na"}>No Preference</ToggleButton>
                </ToggleButtonGroup>
              </Form.Group>
              <Collapse in={this.state.deviceType === TYPE_HARDWARE}>
                <div>
                  <Row>
                    <Col>
                      <Form.Group controlId="bandwidthRange">
                        <Form.Label className={classes.formLabel}>
                          Bandwidth - <i>Subscription Size</i>
                          <Badge hidden variant="warning">
                            Beta
                          </Badge>
                        </Form.Label>
                        <Form.Control
                          type="range"
                          min="0"
                          max="12"
                          value={this.state.bandwidth}
                          onChange={(e) => {
                            this.setState({
                              bandwidth: Number(e.target.value),
                            });
                          }}
                        />
                        <Form.Text className="text-muted">
                          Choose the Bandwidth you'd like to use these devices
                          at, or pick "No Preference" to see all possible
                          configurations.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col xs={3}>
                      <br />
                      <b>
                        {Number(this.state.bandwidth) !== 0
                          ? BANDWIDTH_MAP.get(Number(this.state.bandwidth)) +
                            " Mb/s"
                          : "No Preference"}
                      </b>
                    </Col>
                  </Row>
                </div>
              </Collapse>
              <Form.Group>
                <Form.Label className={classes.formLabel}>
                  Payment Type
                </Form.Label>
                <ToggleButtonGroup
                  type="radio"
                  name="paymentOptions"
                  defaultValue={this.state.payment}
                  style={{ minWidth: "100%", flexWrap: "wrap" }}
                  onChange={(e) => {
                    if (e === PURCHASE) {
                      this.setState({
                        payment: e,
                        paymentType: PAYMENT_PREPAID,
                        paymentLength: DEFAULT_PAYMENT_KEY,
                      });
                    } else if (e === NO_PREFERENCE_PAYMENT) {
                      this.setState({
                        payment: e,
                        paymentType: "na",
                        paymentLength: "na",
                      });
                    } else {
                      this.setState({
                        payment: e,
                        paymentType: PAYMENT_PREPAID,
                        paymentLength: DEFAULT_PAYMENT_KEY,
                      });
                    }
                  }}
                >
                  <ToggleButton
                    value={PURCHASE}
                    disabled={this.state.deviceType === TYPE_SOFTWARE}
                  >
                    CapEx
                  </ToggleButton>
                  <ToggleButton value={RENT}>Rent</ToggleButton>
                  <ToggleButton value={"na"}>No Preference</ToggleButton>
                </ToggleButtonGroup>
                <Collapse in={this.state.payment === RENT}>
                  <div style={{ paddingTop: "10px" }}>
                    <Form.Group>
                      <ToggleButtonGroup
                        name="rentalOptions"
                        style={{ minWidth: "100%", flexWrap: "wrap" }}
                        defaultValue={this.state.paymentType}
                        onChange={(e) => {
                          console.log(e);
                          this.setState({ paymentType: e });
                        }}
                        value={this.state.paymentType}
                      >
                        <ToggleButton value={PAYMENT_PREPAID}>
                          Prepaid
                        </ToggleButton>
                        <ToggleButton value={PAYMENT_ANNUAL}>
                          Annual
                        </ToggleButton>
                        <ToggleButton value={PAYMENT_MONTHLY}>
                          Monthly
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <Form.Text className="text-muted">
                        Choose how often you would like to pay for your rental.
                      </Form.Text>
                    </Form.Group>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Length</Form.Label>
                          <Form.Control
                            type="range"
                            min="0"
                            max="6"
                            value={this.state.paymentLength}
                            onChange={(e) => {
                              this.setState({
                                paymentLength: Number(e.target.value),
                              });
                            }}
                          />
                          <Form.Text className="text-muted">
                            Choose the length you would like to rent the device
                            for.
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <br />
                        <b>
                          {PAYMENT_LENGTH_MAP.get(this.state.paymentLength)}{" "}
                          Month
                          {PAYMENT_LENGTH_MAP.get(this.state.paymentLength) !==
                          1
                            ? "s"
                            : ""}
                        </b>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </Form.Group>
              <Form.Group>
                <Form.Label className={classes.formLabel}>
                  Service Level
                </Form.Label>
                <Form.Control
                  disabled={this.state.deviceType === TYPE_SOFTWARE}
                  as="select"
                  onChange={(e) => {
                    this.setState({ serviceLevel: e.target.value });
                  }}
                  defaultValue={this.state.serviceLevel}
                >
                  <option value={"na"}>No Preference</option>
                  <option value={"4H5"}>4H5 - 4 Hour 9x5 Replacement</option>
                  <option value={"4H7"}>4H7 - 4 Hour 24x7 Replacement</option>
                  <option value={"NDD"}>
                    NDD - Next Business Day Replacement
                  </option>
                  <option value={"RTR"}>RTR - Return Replacement</option>
                </Form.Control>
                <Form.Text className="text-muted">
                  Choose a Service Level, or pick "No Preference" to see all
                  possible configurations.
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label className={classes.formLabel}>
                  Replacement Term
                </Form.Label>
                <Form.Control
                  disabled={this.state.deviceType === TYPE_SOFTWARE}
                  as="select"
                  onChange={(e) => {
                    this.setState({ replacementTerm: e.target.value });
                  }}
                  defaultValue={this.state.replacementTerm}
                >
                  <option value={"na"}>No Preference</option>
                  <option value={"12"}>12 Months (Standard)</option>
                  <option value={"24"}>24 Months (Extended)</option>
                  <option value={"36"}>36 Months (Extended)</option>
                  <option value={"60"}>60 Months (Extended)</option>
                </Form.Control>
                <Form.Text className="text-muted">
                  Choose a Term Length, or pick "No Preference" to see all
                  possible configurations.
                </Form.Text>
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group controlId="quantityRange">
                    <Form.Label className={classes.formLabel}>
                      Quantity
                    </Form.Label>
                    <Form.Control
                      type="range"
                      min={0}
                      max={50}
                      defaultValue={this.state.quantity}
                      onChange={(e) => {
                        this.setState({ quantity: e.target.value });
                      }}
                    />
                    <Form.Text className="text-muted">
                      Quantity does not impact search results.
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col xs={1}>
                  <br />
                  <b>{this.state.quantity}</b>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col sm={8} className={classes.resultsCol}>
            <Container fluid>
              <h1>
                <b>
                  Results{" "}
                  <span style={{ float: "right" }}>
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        this.setState({ showCart: !this.state.showCart });
                      }}
                    >
                      View Cart{" "}
                      <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
                    </Button>
                  </span>
                </b>
              </h1>
              <hr />
              <HWResults
                paymentType={this.state.paymentType}
                paymentLength={PAYMENT_LENGTH_MAP.get(this.state.paymentLength)}
                serviceLevel={this.state.serviceLevel}
                deviceType={this.state.deviceType}
                bandwidth={BANDWIDTH_MAP.get(this.state.bandwidth)}
                quantity={this.state.quantity}
                addItemToCart={this.addItemToCart}
                removeItemFromCart={this.removeItemFromCart}
                cart={this.state.cart}
              />
            </Container>
          </Col>
        </Row>
        <SlidingPane
          isOpen={this.state.showCart}
          overlayClassName={classes.CartPane}
          className={classes.CartPaneAlt}
          title="Your Cart"
          // width="100%"
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            this.setState({ showCart: false });
          }}
        >
          <Cart
            cart={this.state.cart}
            removeItemFromCart={this.removeItemFromCart}
            updateItemInCart={this.updateItemInCart}
          />
        </SlidingPane>
      </div>
    );
  }
}

export default HWSearch;
