import React, { PureComponent } from "react";

import classes from "./Footer.module.css";

class Footer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className={classes.Footer}>
        Designed and Built by{" "}
        <a
          className={classes.a}
          target="_blank"
          rel="noopener noreferrer"
          href="https://linclogix.com/"
        >
          LincLogix LLC
        </a>
        <br />
        Is something broken?{" "}
        <a className={classes.a} href="mailto:hbromund@linclogix.com">
          Submit a Bug Report
        </a>
        <br />
        Hosted on Google App Engine | Version:
        <i>{process.env.REACT_APP_VERSION}</i>
      </div>
    );
  }
}

export default Footer;
