/** The CartItem class contains all properties of items in the Cart */
export class CartItem {
  /**
   *
   * @param {string} partNum the part num of the VeloCloud device
   * @param {number} quantity the number of devices
   * @param {number} price the cost of the device
   */
  constructor(partNum, quantity, price) {
    this.partNum = partNum;
    this.quantity = quantity;
    this.price = price;
  }
}
