import React from "react";
import "./App.css";

import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import NavBar from "./Components/NavBar/NavBar";
import HWSearch from "./Components/HWSearch/HWSearch";
import Footer from "./Components/Footer/Footer";
import "./App.scss";

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faShoppingCart,
  faCartPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

library.add(faShoppingCart, faCartPlus, faTimesCircle);

function App() {
  // console.log(window.location.protocol);
  if (window.location.protocol !== "https:") {
    window.location.replace(
      `https:${window.location.href.substring(window.location.protocol.length)}`
    );
  }

  const successNotify = (partNum) => {
    toast.success("Added " + partNum + " to your Cart!");
  };

  const errorNotify = (partNum) => {
    toast.error("Removed " + partNum + " from your Cart!");
  };

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: "100" }}
        transition={Slide}
      />
      <div>
        <NavBar />
      </div>
      <HWSearch successNotify={successNotify} errorNotify={errorNotify} />
      <Footer />
    </div>
  );
}

export default App;
