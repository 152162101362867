import React, { PureComponent } from "react";
import Alert from "react-bootstrap/Alert";

import CartCard from "./CartCard";
import Card from "react-bootstrap/Card";

import QuoteGenerator from "../QuoteGenerator/QuoteGenerator";

const emptyCart = [
  <Alert variant="warning" key="emptyCartAlert">
    <Alert.Heading>Your Cart is Empty</Alert.Heading>
    <p>Try adding more devices to your cart.</p>
  </Alert>,
];

const cartWarning = [
  <div key="cartWarning">
    <Card border="warning">
      <Card.Body>
        <Card.Title>Not All Configurations are Valid</Card.Title>
        <hr />
        <Card.Text>
          Harbor does its best to help you select valid configurations, but it
          is not possible to guarantee a valid configuration. This means that
          you <i>must</i> check your configuration before sending an invoice to
          VeloCloud.
          <br />A valid configuration consists of a VeloCloud Hardware device
          and a Software plan that matches the speed of the device. To ensure
          your configuration is valid, consult with a member of the LincLogix
          team.
        </Card.Text>
      </Card.Body>
    </Card>
    <br />
  </div>,
];

class Cart extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { cartList: "Loading...", totalCost: 0 };
    this.generateCartList = this.generateCartList.bind(this);
    this.generateTotalCost = this.generateTotalCost.bind(this);
  }

  generateCartList(cart) {
    // // console.log("generateCartList");
    // console.log(cart);

    var output = [];
    if (cart.length !== 0) {
      output.push(cartWarning);
    }
    for (var i = 0; i < cart.length; i++) {
      output.push(
        <div key={cart[i].partNum}>
          <CartCard
            key={cart[i].partNum}
            cartItem={cart[i]}
            removeItemFromCart={this.props.removeItemFromCart}
            updateItemInCart={this.props.updateItemInCart}
          />
        </div>
      );
    }
    if (cart.length === 0) {
      output.push(emptyCart);
    }
    // console.log(output);
    this.setState({ cartList: output });
  }

  generateTotalCost(cart) {
    var totalCost = 0;
    for (var i = 0; i < cart.length; i++) {
      totalCost = totalCost + cart[i].quantity * cart[i].price;
    }
    console.log(totalCost);
    this.setState({ totalCost: totalCost });
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("component did update");

    if (this.props !== prevProps) {
      // console.log(this.props);
      // console.log(prevProps);
      this.generateCartList(this.props.cart);
      this.generateTotalCost(this.props.cart);
    }
  }

  componentDidMount() {
    // console.log("Component Did Mount");
    // console.log(this.props);
    this.generateCartList(this.props.cart);
    this.generateTotalCost(this.props.cart);
    // this.setState({ prevProps: this.props });
  }

  render() {
    var formatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: "USD",
    });
    // console.log(this.state);
    // console.log(this.props);
    return (
      <div>
        {this.state.cartList}
        <br />
        <Card border="primary" hidden={this.props.cart.length === 0}>
          <Card.Body>
            <Card.Title>
              <b>Total</b>
              <span style={{ float: "right" }}>
                <b>{formatter.format(this.state.totalCost)}</b>
              </span>
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Shipping & Handling Charges may also apply
            </Card.Subtitle>
            {/* <Card.Text>
              <Card.Title>{formatter.format(this.state.totalCost)}</Card.Title>
            </Card.Text> */}
          </Card.Body>
        </Card>
        <br />
        <QuoteGenerator
          cart={this.props.cart}
          hidden={this.props.cart.length === 0}
        />
      </div>
    );
  }
}

export default Cart;
