import React, { PureComponent } from "react";

import sourceRegular from "./Poppins/Poppins-Regular.ttf";
import sourceBold from "./Poppins/Poppins-Bold.ttf";
import sourceItalic from "./Poppins/Poppins-Italic.ttf";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import {
  Page,
  Text,
  View,
  Document,
  PDFDownloadLink,
  StyleSheet,
  // PDFViewer,
  Font,
} from "@react-pdf/renderer";

// import classes from "./QuoteGenerator.module.css";

import { getProductDetails } from "../../Utilities/Utilities";
// Font.register({
//   family: "Poppins",
//   fonts: [
//     { src: sourceRegular },
//     { src: sourceBold, fontWeight: "bold" },
//     { src: sourceItalic, fontStyle: "italic" },
//   ],
// });

Font.register({
  family: "Poppins",
  fonts: [
    { src: sourceRegular }, // font-style: normal, font-weight: normal
    { src: sourceItalic, fontStyle: "italic" },
    { src: sourceBold, fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  page: { padding: "20px", textAlign: "left", fontFamily: "Poppins" },
  header: {
    marginBottom: "10px",
    backgroundColor: "#cc0000",
    borderRadius: 5,
    padding: "10px",
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
  },
  subHeader: { fontSize: 12 },
  dateHeader: { fontSize: 12, textAlign: "right" },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    fontSize: 10,
    borderStyle: "solid",
    borderBottomWidth: "1",
  },
  tableCol: {
    width: "20%",
    maxWidth: "20%",
  },
  partCol: { width: "35%" },
  quantityCol: { width: "15%", textAlign: "right" },
  termCol: { width: "20%", textAlign: "left" },
  priceCol: { width: "10%", textAlign: "right" },
  totalCol: { width: "20%", textAlign: "right" },
  tableCell: { margin: 5, textOverflow: "wrap" },
  tableHeading: {
    margin: "auto",
    flexDirection: "row",
    fontSize: 11,
    borderStyle: "solid",
    borderBottomWidth: "1",
    fontWeight: "bold",
  },
  image: {
    maxWidth: "230px",
    maxHeight: "95px",
    width: "auto",
    height: "auto",
  },
});

class QuoteGenerator extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { pdfDownloadVisible: false, pdf: [] };

    this.generateQuotePDF = this.generateQuotePDF.bind(this);
    this.generateTotalPayment = this.generateTotalPayment.bind(this);
    this.generatePaymentLengthString = this.generatePaymentLengthString.bind(
      this
    );
  }

  async componentDidUpdate(prevProps, prevState) {
    console.log(this.props);
    if (this.props !== prevProps) {
      this.setState({ pdfDownloadVisible: false });
      this.setState({ pdf: await this.generateQuotePDF(this.props.cart) });
    }
  }
  async componentDidMount() {
    this.setState({ pdf: await this.generateQuotePDF(this.props.cart) });
  }

  generateTotalPayment(deviceInfo) {
    if (deviceInfo.payment.type === "prepaid") {
      return deviceInfo.price;
    } else if (deviceInfo.payment.type === "annual") {
      return (deviceInfo.price * deviceInfo.payment.length) / 12;
    } else {
      return deviceInfo.price * deviceInfo.payment.length;
    }
  }

  generatePaymentLengthString(deviceInfo) {
    if (deviceInfo.payment.type === "prepaid") {
      return "CapEx";
    } else {
      return deviceInfo.payment.length + " Months";
    }
  }

  async generateQuotePDF(cart) {
    var formatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: "USD",
    });
    var output = [];
    for (var i = 0; i < cart.length; i++) {
      var item = cart[i];
      console.log(item);
      var deviceInfo = await getProductDetails(item.partNum);
      output.push(
        <View style={styles.tableRow}>
          <View style={styles.partCol}>
            <Text style={styles.tableCell}>{item.partNum}</Text>
          </View>
          <View style={styles.quantityCol}>
            <Text style={styles.tableCell}>{item.quantity}</Text>
          </View>
          <View style={styles.termCol}>
            <Text style={styles.tableCell}>
              {this.generatePaymentLengthString(deviceInfo)}
            </Text>
          </View>
          <View style={styles.priceCol}>
            <Text style={styles.tableCell}>
              {formatter.format(this.generateTotalPayment(deviceInfo))}
            </Text>
          </View>
          <View style={styles.totalCol}>
            <Text style={styles.tableCell}>
              {formatter.format(
                item.quantity * Number(this.generateTotalPayment(deviceInfo))
              )}
            </Text>
          </View>
        </View>
      );
    }
    return output;
  }

  render() {
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const date = new Date();

    var pdf = [
      <Document author="Harbor" creator="Harbor" producer="LincLogix">
        <Page size="LETTER" style={styles.page}>
          <View style={styles.header}>
            <Text>Your VeloCloud Order</Text>
          </View>
          <View>
            <Text style={styles.subHeader}>
              This quote does not represent a final order. It is not guaranteed
              to be correct. You must verify that this is a valid VeloCloud
              configuration
            </Text>
          </View>
          <View>
            <Text style={styles.dateHeader}>
              {date.toLocaleDateString("en-US", options)}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHeading}>
              <View style={styles.partCol}>
                <Text style={styles.tableCell}>Part Number</Text>
              </View>
              <View style={styles.quantityCol}>
                <Text style={styles.tableCell}>Quantity</Text>
              </View>
              <View style={styles.termCol}>
                <Text style={styles.tableCell}>Term</Text>
              </View>
              <View style={styles.priceCol}>
                <Text style={styles.tableCell}>Price/Unit</Text>
              </View>
              <View style={styles.totalCol}>
                <Text style={styles.tableCell}>Total</Text>
              </View>
            </View>
            {this.state.pdf}
          </View>
        </Page>
      </Document>,
    ];
    return (
      <div hidden={this.props.hidden}>
        <Card>
          <Card.Body>
            <Card.Title>
              <Row>
                <Col>
                  Download Quote{" "}
                  <Badge variant="secondary">In Development</Badge>
                </Col>
                <Col style={{ textAlign: "right" }} xs="4">
                  {this.state.pdfDownloadVisible ? (
                    <PDFDownloadLink
                      document={pdf}
                      fileName={
                        "VeloCloud-Quote-" +
                        String(date.getMonth() + 1) +
                        "-" +
                        date.getDate() +
                        "-" +
                        date.getFullYear() +
                        ".pdf"
                      }
                      // className={classes.downloadLink}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? "Loading document..." : "Download PDF"
                      }
                    </PDFDownloadLink>
                  ) : (
                    <Button
                      variant="link"
                      size="lg"
                      onClick={() => {
                        this.setState({ pdfDownloadVisible: true });
                      }}
                    >
                      Generate PDF
                    </Button>
                  )}
                </Col>
              </Row>
            </Card.Title>

            {/* <PDFViewer style={{ width: "100%", height: "50vh" }}>
              {pdf}
            </PDFViewer> */}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default QuoteGenerator;
