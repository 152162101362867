import React, { PureComponent } from "react";

import { getProductDetails } from "../../Utilities/Utilities";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
// import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classes from "./HWCard.module.css";

class HWCard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { deviceInfo: null, inCart: false };

    this.isInCart = this.isInCart.bind(this);
    this.generatePaymentLengthString = this.generatePaymentLengthString.bind(
      this
    );
    this.generateTotalCost = this.generateTotalCost.bind(this);
  }

  isInCart() {
    for (var i = 0; i < this.props.cart.length; i++) {
      if (this.props.cart[i].partNum === this.props.partNum) {
        return true;
      }
    }
    return false;
  }

  async componentDidMount() {
    var deviceInfo = await getProductDetails(this.props.partNum);
    var inCartTemp = this.isInCart();
    this.setState({ deviceInfo: deviceInfo, inCart: inCartTemp });
  }

  generatePaymentLengthString() {
    if (this.state.deviceInfo.payment.type === "prepaid") {
      return "OTC";
    } else if (this.state.deviceInfo.payment.type === "annual") {
      return this.state.deviceInfo.payment.length / 12 + " Years";
    } else {
      return this.state.deviceInfo.payment.length + " Months";
    }
  }

  generateTotalCost() {
    if (this.state.deviceInfo.payment.type === "prepaid") {
      return this.state.deviceInfo.price;
    } else if (this.state.deviceInfo.payment.type === "annual") {
      return (
        (this.state.deviceInfo.price * this.state.deviceInfo.payment.length) /
        12
      );
    } else {
      return this.state.deviceInfo.price * this.state.deviceInfo.payment.length;
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // console.log(this.props);
    if (this.props !== prevProps) {
      // if the current props are different from the last props
      if (this.props.partNum !== prevProps.partNum) {
        // if the part number does not change, we should not make another call to the backend
        var deviceInfo = await getProductDetails(this.props.partNum);
        this.setState({ deviceInfo: deviceInfo });
      }
      var inCartTemp = this.isInCart();
      this.setState({ inCart: inCartTemp });
    }
  }

  render() {
    var formatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: "USD",
    });
    // console.log(this.state);
    if (this.state.deviceInfo === null) {
      return <div></div>;
    }
    return (
      <div>
        <Card className={classes.HWCard}>
          <Card.Body>
            <Card.Title style={{ textAlign: "center" }}>
              {this.state.deviceInfo.HWDevice
                ? this.state.deviceInfo.HWDevice.name
                : this.state.deviceInfo.product}
            </Card.Title>
            <Card.Subtitle
              className="mb-2 text-muted"
              style={{ textAlign: "center" }}
            >
              {formatter.format(this.state.deviceInfo.price)} |{" "}
              {this.generatePaymentLengthString()} |{" "}
              {formatter.format(Number(this.generateTotalCost()))}
              {/* <Badge variant="success">
                {formatter.format(
                  this.state.deviceInfo.price * this.props.quantity
                )}
              </Badge> */}
            </Card.Subtitle>
            <hr />
            <Card.Text>{this.state.deviceInfo.comments}</Card.Text>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs="auto" style={{ paddingLeft: "30px" }}>
                <Row>
                  <b>{this.state.deviceInfo.productFamily}</b> |{" "}
                  {this.state.deviceInfo.platformGroup}
                </Row>
                <Row>
                  <i>{this.state.deviceInfo.partNum}</i>
                </Row>
              </Col>

              <Col style={{ textAlign: "right" }} className="my-auto">
                <Button
                  variant={
                    this.state.inCart ? "outline-primary" : "outline-secondary"
                  }
                  onClick={() => {
                    if (this.state.inCart === false) {
                      this.props.addItemToCart(
                        this.state.deviceInfo.partNum,
                        this.props.quantity,
                        Number(this.generateTotalCost())
                      );
                    } else {
                      this.props.removeItemFromCart(
                        this.state.deviceInfo.partNum
                      );
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={[
                      "fas",
                      this.state.inCart ? "times-circle" : "cart-plus",
                    ]}
                  />
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </div>
    );
  }
}

export default HWCard;
