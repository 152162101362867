import React, { PureComponent } from "react";

import Navbar from "react-bootstrap/Navbar";
// import Button from "react-bootstrap/Button";
// import SlidingPane from "react-sliding-pane";
// import Nav from "react-bootstrap/Nav";
// import NavDropdown from "react-bootstrap/NavDropdown";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import Cart from "../Cart/Cart";

import classes from "./NavBar.module.css";

class NavBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <Navbar bg="light" expand="lg" className={classes.NavBar}>
          <Navbar.Brand>
            <img
              src={require("../../assets/HarborWordmarkBlack.png")}
              className="d-inline-block align-top"
              width="200"
              alt="Harbor"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {/* <Button
              variant="outline-primary"
              onClick={() => {
                this.setState({ showCart: !this.state.showCart });
              }}
            >
              View Cart <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
            </Button> */}
          </Navbar.Collapse>
        </Navbar>
        {/* <SlidingPane
          isOpen={this.state.showCart}
          overlayClassName={classes.CartPane}
          title="Cart"
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            this.setState({ showCart: false });
          }}
        >
          <Cart />
        </SlidingPane> */}
      </div>
    );
  }
}

export default NavBar;
